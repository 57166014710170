* {
  box-sizing: border-box;
}

.App {
  font-family: Arial ;
  margin-left: 20px;
  margin-right: 20px;
}
.footer{
  padding-bottom: 10em;
}

.Reports {
  margin-left: 10px;
  margin-right: 10px;
}
.Receive {
  margin-left: 10px;
  margin-right: 10px;
}
.content{

}
.buttonarea{
  padding-top:1em;
  padding-bottom:2em;
  padding-left: 2em;
  margin-top:auto;
}


.pickercomponents{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 20px;
  padding-left: 20px;
  height: 8em;
  margin-top:auto;
}

.inputcomponents{
  padding-left: 1em;
  display: flex;
  flex-direction: column;
}

.wardautocomplete{
  min-width: 12rem;
}
.inputs{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 10em;
  padding-bottom: 3em;
  padding-top: 2em;
  background-color: white;
}

.subTitle1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
}

.buttontitlestrip{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
}


.audittables{
  display: flex;
  flex-direction: column;
  margin-top: 4em;
  margin-bottom:2em;
}
.adjustbutton {
  height: 30px;
  margin-top: 10px;
  margin-right: 10px;
  /*width: 100px;*/
  border-radius: 10px;
  color: hsl(0, 0%, 99%);
  background-color: #0074AF ;
  font-family: inherit;
  font-size: 1rem;
  outline-style: none;
}

.reportsearchlink {
  height: 60px;
  margin-top: 40px;
  margin-bottom:auto;
  margin-left: 10px;
  width: 150px;
  border-radius: 5px;
  color: hsl(0, 0%, 99%);
  background-color: #0074AF ;
  font-family: inherit;
  font-size: 2rem;
  text-decoration: none;
}

.reportsearchbutton {
  height: 30px;
  margin-top: auto;
  margin-bottom:auto;
  margin-left: 50px;
  width: 170px;
  border-radius: 10px;
  color: hsl(0, 0%, 99%);
  background-color: #0074AF ;
  font-family: inherit;
  font-size: 1rem;
  outline-style: none;
}

.ministryreportbutton {
  height: 30px;
  margin-top: auto;
  margin-bottom:auto;
  margin-left: 50px;
  width: 170px;
  border-radius: 10px;
  color: hsl(0, 0%, 99%);
  background-color: hsl(0, 0%, 0%);
  font-family: inherit;
  font-size: 1rem;
  outline-style: none;
}


.receivebutton {
  height: 30px;
  margin-top: 10px;
  margin-right: 10px;
  width: 100px;
  border-radius: 10px;
  font-family: inherit;
  /*color: hsl(0, 0%, 99%);*/
  /*background-color: hsl(224, 80%, 48%);*/
  background-color: #0074AF ;
  color: white ;
  font-size: 1rem;
  outline-style: none;
}

.pickbutton {

  height: 30px;
  margin-top: 10px;
  width: 100px;
  border-radius: 10px;
  color: hsl(0, 0%, 99%);
  background-color: #0074AF ;
  font-family: inherit;
  font-size: 1rem;
  outline-style: none;

}
.searchbutton {

  height: 40px;
  margin-top: 20px;
  width: 150px;
  border-radius: 1000000px;
  color: hsl(0, 0%, 99%);
  background-color: #0074AF ;
  font-family: inherit;
  font-size: 1.1rem;
  outline-style: none;

}
.subtitleh{
  font-size: 0.8rem;
  color: #0074AF ;
}

.full-screen-header{
  grid-area:header;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: 5E5E5E;
  height: 8vh;
  color:white;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-top: 1em;

  background-position-x: 55% ;
  background-position-y: -1%;
  position: relative;

}

.formcontrol{
  padding-top:20px;
  padding-bottom: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.textin{
  padding-bottom: 100px;
  width: 100%;
}
.siteselect{
  width: 15em;
  padding-bottom: 1em;
  padding-right: 50px;
}

.siteselectdock{
  width: 20em;
  padding-left: 5em;
  padding-right: 50px;
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
